<template>
    <v-card v-if="visible" min-width="460">
        <v-toolbar color="default" dark dense>
            <v-toolbar-title>
                <small>
                    <span v-show="status===1">Načítám... | </span>
                    <v-icon>phone</v-icon>
                    Dopravní zaměstnanci
                </small>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items class="pl-3">
                <v-btn fab x-small color="red" @click="close">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card tile v-if="status === 5" max-height="50vh" class="scrollable">
            <v-card-text>
                <h3>Přejezd: {{ item.name }} <small v-if="item.note">({{ item.note }})</small></h3>
                Vzdálenost: {{ formatDistance(item.distance) }}
            </v-card-text>
            <contact-list can-send :items="item.contacts"></contact-list>
        </v-card>
        <v-card tile v-else-if="status === 6" max-height="50vh" class="scrollable">
            <v-card-text>
                <h3>Kontakty na dopravní zaměstnance nelze zjistit</h3>
            </v-card-text>
        </v-card>
    </v-card>
</template>

<script>

import {mapGetters, mapState} from "vuex";
import ContactList from '@/components/ui/contactList/ContactList'
import {formatDistance} from "@/helpers/format";

export default {
    name: 'card-explore',
    components: {
        ContactList,
    },
    data() {
        return {}
    },
    computed: {
        item() {
            return this.items.crossings[0];
        },
        ...mapState({
            status: state => state.explore.status,
            items: state => state.explore.items,
        }),
        ...mapGetters('explore', {
            visible: 'visible',
        }),
    },
    methods: {
        close() {
            this.$store.dispatch('explore/end');
        },
        formatDistance(value) {
          return formatDistance(value);
        },
    },
}
</script>
